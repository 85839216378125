// Dish.js

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import DishContent from "./DishContent";
import VideoWithLoader from "./VideoWithLoader";
import "./Dish.css";

const Dish = ({ dish, icon, dishType, whatsappNumber }) => {
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(dish.likes || 0);
  const [animate, setAnimate] = useState(false);
  const [muted, setMuted] = useState(true);
  const videoRef = useRef(null);
  const isIntersecting = useIntersectionObserver(videoRef, {
    threshold: 0.5,
  });

  const toggleLike = () => {
    const newLikes = liked ? likes - 1 : likes + 1;
    setLiked(!liked);
    setLikes(newLikes);
    setAnimate(true);
    setTimeout(() => setAnimate(false), 700);
  };

  const formatLikes = (number) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + "m";
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + "k";
    }
    return number;
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  if (!dish) {
    return <div className="dish">No data available</div>;
  }

  return (
    <div className="dish" ref={videoRef}>
      <div className="dish-video">
        <VideoWithLoader
          url={dish.video}
          poster={dish.featuredImageLink}
          playing={isIntersecting}
          muted={muted}
        />
      </div>
      <DishContent
        dish={dish}
        dish_id={dish.dish_id} // Asegúrate de pasar dish_id
        icon={icon}
        dishType={dishType}
        toggleLike={toggleLike}
        liked={liked}
        likes={likes}
        animate={animate}
        formatLikes={formatLikes}
        muted={muted}
        toggleMuted={toggleMuted}
        whatsappNumber={whatsappNumber} // Pasar whatsappNumber aquí
      />
    </div>
  );
};

Dish.propTypes = {
  dish: PropTypes.shape({
    dish_id: PropTypes.number.isRequired, // Asegúrate de que el dish_id esté definido y sea requerido
    dishName: PropTypes.string,
    allergens: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    price: PropTypes.number,
    video: PropTypes.string,
    content: PropTypes.string,
    featuredImageLink: PropTypes.string,
    likes: PropTypes.number,
  }).isRequired,
  icon: PropTypes.string,
  dishType: PropTypes.string,
  whatsappNumber: PropTypes.string.isRequired, // Asegúrate de que el número de WhatsApp sea obligatorio
};

export default Dish;

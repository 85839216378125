import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./ThumbsSlider.css";
import getIcon from "../../utils/getIcon";

const ThumbsSlider = ({ menu, setThumbSwiper, onThumbClick, activeIndex }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      setThumbSwiper(swiperRef.current.swiper);
    }
  }, [setThumbSwiper]);

  const handleThumbClick = (index) => {
    onThumbClick(index);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  if (!menu || menu.length === 0) {
    return <div>No menu data available</div>;
  }

  return (
    <div className="thumbs-slider-container">
      <div className="thumbs-slider-wrapper">
        <Swiper
          ref={swiperRef}
          direction="horizontal"
          slidesPerView={5}
          spaceBetween={5}
          slidesPerGroup={1}
          className="thumbs-slider"
          watchSlidesProgress
          navigation
          modules={[Navigation]}
        >
          {menu.map((dishType, index) => {
            const IconComponent = getIcon(dishType.icon);
            return (
              <SwiperSlide
                key={index}
                className={`thumb-slide ${
                  index === activeIndex ? "thumb-slide-active" : ""
                }`}
                onClick={() => handleThumbClick(index)}
              >
                <div className="thumb">
                  {IconComponent && <IconComponent className="thumb-icon" />}
                  <span className="thumb-name">{dishType.dishType}</span>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default ThumbsSlider;

import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import "./VideoWithLoader.css";

const VideoWithLoader = ({ url, poster, playing, muted }) => {
  const playerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(playing);

  useEffect(() => {
    if (playerRef.current && isPlaying) {
      playerRef.current.seekTo(0); // Seek to the start of the video
    }
  }, [isPlaying]);

  useEffect(() => {
    setIsPlaying(playing);
  }, [playing]);

  return (
    <div className="video-container">
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={isPlaying}
        muted={muted}
        loop
        playsinline
        width="100%"
        height="100%"
        className="react-player"
        config={{
          file: {
            attributes: {
              poster: poster,
            },
          },
        }}
      />
    </div>
  );
};

export default VideoWithLoader;

// src/components/Preloader.js
import React from "react";
import "./Preloader.css";

const Preloader = ({ message }) => {
  return (
    <div className="preloader">
      <div className="spinner"></div>
      <p>{message}</p>
    </div>
  );
};

export default Preloader;

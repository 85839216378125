import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import Dish from "./Dish";
import "./DishSlider.css";

const DishSlider = ({
  dishes,
  icon,
  dishType,
  onSwiper,
  initialSlide,
  whatsappNumber,
}) => {
  const swiperRef = useRef(null);

  return (
    <Swiper
      ref={swiperRef}
      direction="vertical"
      slidesPerView={1}
      spaceBetween={0}
      pagination={{ clickable: true }}
      modules={[Pagination]}
      className="vertical-slider"
      initialSlide={initialSlide}
      onSwiper={(swiper) => {
        onSwiper(swiper);
        swiperRef.current = swiper;
      }}
    >
      {dishes.map((dish, index) => (
        <SwiperSlide key={index}>
          <Dish
            dish={dish}
            icon={icon}
            dishType={dishType}
            whatsappNumber={whatsappNumber}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default DishSlider;

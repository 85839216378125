import React from "react";
import "./Header.css";
import getIcon from "../utils/getIcon";
import cartaIcon from "../icons/icon-noticias.svg";

const Header = ({ restaurant, onCartaClick, isCartaOpen }) => {
  const RestaurantIcon = getIcon(restaurant["logo-white"]);

  return (
    <div className="header">
      {RestaurantIcon && <RestaurantIcon className="restaurant-image" />}
      <img
        src={cartaIcon}
        alt="Carta Básica"
        className={`carta-icon ${isCartaOpen ? "active" : ""}`}
        onClick={onCartaClick}
      />
    </div>
  );
};

export default Header;

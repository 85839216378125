// src/components/menu/MenuSection.js
import React from "react";
import "./MenuSection.css";

const MenuSection = ({ menu }) => {
  return (
    <section>
      <br></br>
      {menu
        .filter((menuItem) => menuItem.menu_activo) // Filtrar menús activos
        .map((menuItem, menuIndex) => (
          <div className="contenedor-menu" key={menuIndex}>
            <h2 className="menu-title">{menuItem.nombre}</h2>
            {menuItem.categoria.map((category, categoryIndex) => (
              <div key={categoryIndex}>
                <h3>{category.nombre}</h3>
                <ul>
                  {category.platos.map((dish, dishIndex) => (
                    <li className="dish-wrapper" key={dishIndex}>
                      <div className="menu-dish-item">
                        <div className="menu-dish-name">
                          <span className="menu-dish-name">
                            {dish.dishName}
                          </span>
                          {dish.allergens && dish.allergens.length > 0 && (
                            <span className="menu-alergenos">
                              {" a.: "}
                              {dish.allergens.join(", ")}
                            </span>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <h4 className="menu-price">{menuItem.precio}€</h4>
          </div>
        ))}
    </section>
  );
};

export default MenuSection;

// src/icons/heart.jsx
import React from "react";

const MutedIcon = ({ filled }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4 19.8L15.375 16.775C14.9583 17.0417 14.5167 17.2708 14.05 17.4625C13.5833 17.6542 13.1 17.8083 12.6 17.925V15.875C12.8333 15.7917 13.0625 15.7083 13.2875 15.625C13.5125 15.5417 13.725 15.4417 13.925 15.325L10.6 12V17.2L5.6 12.2H1.6V6.2H4.8L0 1.4L1.4 0L19.8 18.4L18.4 19.8ZM18.2 14L16.75 12.55C17.0333 12.0333 17.2458 11.4917 17.3875 10.925C17.5292 10.3583 17.6 9.775 17.6 9.175C17.6 7.60833 17.1417 6.20833 16.225 4.975C15.3083 3.74167 14.1 2.90833 12.6 2.475V0.425C14.6667 0.891667 16.35 1.9375 17.65 3.5625C18.95 5.1875 19.6 7.05833 19.6 9.175C19.6 10.0583 19.4792 10.9083 19.2375 11.725C18.9958 12.5417 18.65 13.3 18.2 14ZM14.85 10.65L12.6 8.4V5.15C13.3833 5.51667 13.9958 6.06667 14.4375 6.8C14.8792 7.53333 15.1 8.33333 15.1 9.2C15.1 9.45 15.0792 9.69583 15.0375 9.9375C14.9958 10.1792 14.9333 10.4167 14.85 10.65ZM10.6 6.4L8 3.8L10.6 1.2V6.4ZM8.6 12.35V10L6.8 8.2H3.6V10.2H6.45L8.6 12.35Z"
      fill="white"
    />
  </svg>
);

export default MutedIcon;

import apiData from "./apiData.json"; // Importar el JSON local

// Obtener el primer restaurant_id como valor predeterminado
const defaultRestaurantID = apiData[0].restaurant.restaurant_id;

export const fetchMenuData = async (r_ID = defaultRestaurantID) => {
  try {
    const data = apiData.find(
      (item) => item.restaurant.restaurant_id === parseInt(r_ID, 10)
    );

    if (!data) {
      throw new Error("No data found for the given restaurant ID");
    }

    return data;
  } catch (error) {
    console.error("Error fetching menu data:", error);
    throw error;
  }
};

// src/data/DataCartaBasica.js
import { useState, useEffect } from "react";
import dataCartaBasica from "./DataCartaBasica.json"; // Importar el JSON local

export const useFetchMenus = (restaurant_id) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(`Fetching data for restaurant ID: ${restaurant_id}`);
        const data = dataCartaBasica.find(
          (item) => item.restaurant.restaurant_id === parseInt(restaurant_id)
        );

        if (!data) {
          throw new Error("No data found for the given restaurant ID");
        }

        setData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [restaurant_id]);

  return { data, loading, error };
};

// src/utils/getIcon.js

import { ReactComponent as ComendadorWhite } from "../icons/fincas/comendador-white.svg";
import { ReactComponent as ComendadorBlack } from "../icons/fincas/comendador-black.svg";
import { ReactComponent as GgreenWhite } from "../icons/fincas/ggreen-white.svg";
import { ReactComponent as GgreenBlack } from "../icons/fincas/ggreen-black.svg";
import { ReactComponent as IconCatering } from "../icons/secciones/icon-catering.svg";
import { ReactComponent as IconCeremonia } from "../icons/secciones/icon-ceremonia.svg";
import { ReactComponent as IconCoctel } from "../icons/secciones/icon-coctel.svg";
import { ReactComponent as IconDecoracion } from "../icons/secciones/icon-decoracion.svg";
import { ReactComponent as IconDiscoteca } from "../icons/secciones/icon-discoteca.svg";
import { ReactComponent as IconExtras } from "../icons/secciones/icon-extras.svg";
import { ReactComponent as IconFinca } from "../icons/secciones/icon-finca.svg";
import { ReactComponent as IconJardines } from "../icons/secciones/icon-jardines.svg";
import { ReactComponent as IconSalones } from "../icons/secciones/icon-salones.svg";


const iconMapping = {
  ComendadorWhite:  ComendadorWhite,
  ComendadorBlack:  ComendadorBlack,
  GgreenWhite: GgreenWhite,
  GgreenBlack: GgreenBlack,
  "icon-catering": IconCatering,
  "icon-ceremonia": IconCeremonia,
  "icon-coctel": IconCoctel,
  "icon-decoracion": IconDecoracion,
  "icon-discoteca": IconDiscoteca,
  "icon-extras": IconExtras,
  "icon-finca": IconFinca,
  "icon-jardines": IconJardines,
  "icon-salones": IconSalones,
};

const getIcon = (iconName) => {
  return iconMapping[iconName] || null;
};

export default getIcon;

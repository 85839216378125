import React, { useEffect } from "react";
import DishTypeSlider from "./carta/DishTypeSlider";
import "./Home.css";

const Home = ({ menu, whatsappNumber }) => {
  useEffect(() => {
    const handleResize = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Filtrar los menús para incluir solo los que tienen videos
  const filteredMenu = menu
    .map((menuItem) => ({
      ...menuItem,
      dishes: menuItem.dishes.filter((dish) => dish["tiene-video"]),
    }))
    .filter((menuItem) => menuItem.dishes.length > 0);

  return (
    <div className="home">
      <DishTypeSlider menu={filteredMenu} whatsappNumber={whatsappNumber} />
    </div>
  );
};

export default Home;

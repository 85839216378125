import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSwipeable } from "react-swipeable";
import ThumbsSlider from "./ThumbsSlider";
import DishSlider from "./DishSlider";
import getIcon from "../../utils/getIcon";
import "swiper/css";
import "swiper/css/thumbs";
import "./DishTypeSlider.css";
import "./DishSlider.css";

const DishTypeSlider = ({ menu, whatsappNumber }) => {
  const [currentDishType, setCurrentDishType] = useState(menu[0]);
  const [thumbSwiper, setThumbSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const verticalSwiperRef = useRef(null);
  const horizontalSwipers = useRef([]);

  const handleSlideChange = () => {
    const mainSwiper = verticalSwiperRef.current.swiper;
    const activeIndex = mainSwiper.activeIndex;
    setCurrentDishType(menu[activeIndex]);
    setActiveIndex(activeIndex);

    if (thumbSwiper && thumbSwiper.slides) {
      thumbSwiper.slideTo(activeIndex);
      thumbSwiper.slides.forEach((slide, index) => {
        if (index === activeIndex) {
          slide.classList.add("thumb-slide-active");
        } else {
          slide.classList.remove("thumb-slide-active");
        }
      });
    }
  };

  const handleThumbClick = (index) => {
    verticalSwiperRef.current.swiper.slideTo(index);
    setTimeout(() => {
      if (horizontalSwipers.current[index]) {
        horizontalSwipers.current[index].slideTo(0, 0);
      }
    }, 300);
  };

  const swipeHandlers = useSwipeable({
    onSwipedUp: () => {
      const mainSwiper = verticalSwiperRef.current.swiper;
      const activeNestedSwiper =
        horizontalSwipers.current[mainSwiper.activeIndex];
      if (!activeNestedSwiper || activeNestedSwiper.isEnd) {
        if (mainSwiper.activeIndex < mainSwiper.slides.length - 1) {
          mainSwiper.slideNext();
          setTimeout(() => {
            const nextIndex = mainSwiper.activeIndex;
            if (horizontalSwipers.current[nextIndex]) {
              horizontalSwipers.current[nextIndex].slideTo(0, 0);
            }
          }, 300);
        }
      }
    },
    onSwipedDown: () => {
      const mainSwiper = verticalSwiperRef.current.swiper;
      const activeNestedSwiper =
        horizontalSwipers.current[mainSwiper.activeIndex];
      if (!activeNestedSwiper || activeNestedSwiper.isBeginning) {
        if (mainSwiper.activeIndex > 0) {
          const prevIndex = mainSwiper.activeIndex - 1;
          mainSwiper.slideTo(prevIndex);
          setTimeout(() => {
            if (horizontalSwipers.current[prevIndex]) {
              const lastSlideIndex =
                horizontalSwipers.current[prevIndex].slides.length - 1;
              horizontalSwipers.current[prevIndex].slideTo(lastSlideIndex, 0);
            }
          }, 300);
        }
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true, // Esto permite que el deslizamiento funcione en escritorio
  });

  const IconComponent = getIcon(currentDishType.icon);

  return (
    <div className="slider-container" {...swipeHandlers}>
      <div className="dish-category">
        <span className="dish-type-name">{currentDishType.dishType}</span>
        {IconComponent && <IconComponent className="dish-type-icon" />}
      </div>
      <Swiper
        direction="horizontal"
        slidesPerView={1}
        spaceBetween={0}
        className="vertical-slider"
        ref={verticalSwiperRef}
        onSlideChange={handleSlideChange}
      >
        {menu.map((dishType, index) => (
          <SwiperSlide key={index}>
            <DishSlider
              dishes={dishType.dishes}
              icon={dishType.icon}
              dishType={dishType.dishType}
              initialSlide={0}
              onSwiper={(swiper) => (horizontalSwipers.current[index] = swiper)}
              whatsappNumber={whatsappNumber}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <ThumbsSlider
        menu={menu}
        setThumbSwiper={setThumbSwiper}
        onThumbClick={handleThumbClick}
        activeIndex={activeIndex}
      />
      {menu.length === 1 && (
        <div className="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-vertical force-display">
          <span
            style={{ display: "block !important" }}
            className="swiper-pagination-bullet swiper-pagination-bullet-active force-display"
          ></span>
        </div>
      )}
    </div>
  );
};

export default DishTypeSlider;

// src/components/CartaBasica.js
import React, { useState, useEffect } from "react";
import { useFetchMenus } from "../../data/DataCartaBasica";
import { fetchMenuData } from "../../data/api";
import getIcon from "../../utils/getIcon"; // Importar getIcon
import cartabasicaIcon from "../../icons/arrow.svg";
import MenuSection from "../menu/MenuSection";
import CartaSection from "../menu/CartaSection";
import "./CartaBasica.css";

const CartaBasica = ({ onClose, restaurant, restaurant_id }) => {
  const {
    data: menuData,
    loading: menuLoading,
    error: menuError,
  } = useFetchMenus(restaurant_id);
  const [cartaData, setCartaData] = useState(null);
  const [cartaLoading, setCartaLoading] = useState(true);
  const [cartaError, setCartaError] = useState(null);

  useEffect(() => {
    const getCartaData = async () => {
      try {
        const data = await fetchMenuData(restaurant_id);
        console.log("Fetched Carta Data:", data);
        setCartaData(data.carta);
        setCartaLoading(false);
      } catch (error) {
        setCartaError(error);
        setCartaLoading(false);
      }
    };

    getCartaData();
  }, [restaurant_id]);

  if (menuLoading || cartaLoading) {
    return <div>Loading...</div>;
  }

  if (menuError) {
    return <div>Error fetching menu data: {menuError.message}</div>;
  }

  if (cartaError) {
    return <div>Error fetching carta data: {cartaError.message}</div>;
  }

  if (!menuData || !cartaData) {
    return <div>No data available</div>;
  }

  const { menu } = menuData;

  console.log("Restaurant:", restaurant);
  console.log("Logo dark:", restaurant["logo-dark"]); // Punto de control para verificar el logo
  console.log("Menu:", menu);
  console.log("Carta:", cartaData);

  const RestaurantLogo = getIcon(restaurant["logo-dark"]);

  return (
    <div className="carta-basica">
      <div className="carta-container">
        <img
          src={cartabasicaIcon}
          alt="Carta Video"
          className="close-button"
          onClick={onClose}
        />
        <header className="carta-header">
          {RestaurantLogo ? (
            <RestaurantLogo className="restaurant-image" />
          ) : (
            <div>No logo available</div>
          )}
        </header>
        <MenuSection menu={menu} />
        <CartaSection carta={cartaData} />
      </div>
    </div>
  );
};

export default CartaBasica;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import getIcon from "../../utils/getIcon";
import HeartIcon from "../../icons/HeartIcon";
import MutedIcon from "../../icons/MutedIcon";
import SoundIcon from "../../icons/SoundIcon";
import WhatsAppButton from "../WhatsAppButton"; // Importa el componente WhatsAppButton
import "./DishContent.css";

const DishContent = ({
  dish,
  dish_id,
  icon,
  dishType,
  toggleLike,
  liked,
  likes,
  animate,
  formatLikes,
  muted,
  toggleMuted,
  whatsappNumber,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [localLiked, setLocalLiked] = useState(false);

  useEffect(() => {
    const localLikedStatus = localStorage.getItem(`liked-${dish_id}`);
    if (localLikedStatus === "true") {
      setLocalLiked(true);
    }
  }, [dish_id]);

  const handleLikeClick = () => {
    if (isUpdating) return;

    const newLikes = localLiked ? likes - 1 : likes + 1;

    setIsUpdating(true);
    try {
      toggleLike();
      setLocalLiked(!localLiked);
      localStorage.setItem(`liked-${dish_id}`, !localLiked);
    } catch (error) {
      console.error("Error updating likes:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="dish-content">
      <div className="dish-item-left">
        <h2
          className="dish-title"
          dangerouslySetInnerHTML={{ __html: dish.dishName }}
        />
        {dish.content && (
          <div
            className="dish-content-detail"
            dangerouslySetInnerHTML={{ __html: dish.content }}
          />
        )}
        
      </div>
      <div className="dish-item-right">
        <div className="like-container">
          <div
            className={`like-icon ${animate ? "animate" : ""}`}
            onClick={handleLikeClick}
          >
            <HeartIcon filled={liked || localLiked} />
            {animate && <div className="floating-heart">❤️</div>}
          </div>
          <div className="like-count">{formatLikes(likes)}</div>
        </div>
        {dish["video-con-sonido"] && dish["video-con-sonido"] === true && (
          <button onClick={toggleMuted} className="mute-button">
            {muted ? (
              <MutedIcon className="mute-icon" />
            ) : (
              <SoundIcon className="mute-icon" />
            )}
          </button>
        )}
        <WhatsAppButton whatsappNumber={whatsappNumber} />{" "}
      </div>
    </div>
  );
};

DishContent.propTypes = {
  dish: PropTypes.shape({
    dishName: PropTypes.string,
    allergens: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    price: PropTypes.number,
    content: PropTypes.string,
    "video-con-sonido": PropTypes.bool,
  }).isRequired,
  dish_id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  dishType: PropTypes.string,
  toggleLike: PropTypes.func.isRequired,
  liked: PropTypes.bool.isRequired,
  likes: PropTypes.number.isRequired,
  animate: PropTypes.bool.isRequired,
  formatLikes: PropTypes.func.isRequired,
  muted: PropTypes.bool.isRequired,
  toggleMuted: PropTypes.func.isRequired,
  whatsappNumber: PropTypes.string.isRequired,
};

export default DishContent;

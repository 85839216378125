import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import ThumbsSlider from "./components/carta/ThumbsSlider";
import CartaBasica from "./components/carta/CartaBasica";
import Preloader from "./components/Preloader";
import { fetchMenuData } from "./data/api"; // Importación nombrada
import useDocumentTitle from "./hooks/useDocumentTitle";
import apiData from "./data/apiData.json"; // Importa el JSON local
import "./App.css";

function App() {
  const [isCartaBasicaOpen, setIsCartaBasicaOpen] = useState(false);
  const [menuData, setMenuData] = useState(null);
  const restaurantId = apiData[0].restaurant.restaurant_id; // Obtén el primer restaurant_id por defecto

  const toggleCartaBasica = () => {
    setIsCartaBasicaOpen(!isCartaBasicaOpen);
  };

  useEffect(() => {
    const getMenuData = async () => {
      try {
        const data = await fetchMenuData(restaurantId);
        setMenuData(data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    getMenuData();
  }, [restaurantId]);

  useDocumentTitle(
    menuData ? `${menuData.restaurant.name}` : "Cargando..."
  );

  if (!menuData) {
    return <Preloader message="Cargando datos..." />;
  }

  const filteredMenu = menuData.carta
    .map((menuItem) => ({
      ...menuItem,
      dishes: menuItem.dishes.filter((dish) => dish["tiene-video"]),
    }))
    .filter((menuItem) => menuItem.dishes.length > 0);

  return (
    <Router>
      <div className="App">
        <Header
          restaurant={menuData.restaurant}
          onCartaClick={toggleCartaBasica}
          isCartaOpen={isCartaBasicaOpen}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                menu={filteredMenu}
                whatsappNumber={menuData.restaurant.whatsapp}
              />
            }
          />
        </Routes>
        {!isCartaBasicaOpen && <ThumbsSlider />}
        {isCartaBasicaOpen && (
          <CartaBasica
            restaurant={menuData.restaurant}
            restaurant_id={restaurantId}
            onClose={toggleCartaBasica}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
